(function ( $ ) {
    var windowW = 0;
    var windowH = 0;
    var measureWindow = function () {
        windowW = $(window).width();
        windowH = $(window).height();
    };
    measureWindow();
    var getImageDimensions = function ( w, h ) {
        var ratio = h / w;
        if (w >= h) {
            return {
                width: windowW,
                height: windowW * ratio
            };
        } else {
            return {
                width: windowH / ratio,
                height: windowH
            };
        }
    };
    $('.photoswipe-pictures').each(function () {
        var $pic = $(this),
            getItems = function () {
                var items = [];
                $pic.find('a.photoswipe-a').each(function () {
                    var $size = $(this).data('size').split('x');
                    var dimensions = getImageDimensions($size[0], $size[1]);
                    var $href = $(this).attr('href');

                    var item = {
                        src: $href,
                        w: dimensions.width,
                        h: dimensions.height
                    };

                    items.push(item);
                });
                return items;
            };

        var items = getItems();
        var $pswp = $('.pswp')[0];

        $pic.on('click', 'figure', function ( event ) {
            var scroll = $(document).scrollTop();
            event.preventDefault();

            var $index = $(this).data('index');
            var options = {
                index: $index,
                modal: true,
                bgOpacity: 0.7,
                showHideOpacity: true,
                closeOnScroll: true,
                spacing: 0,
                preload: [3,3],
                maxSpreadZoom: 4
            };

            // Initialize PhotoSwipe
            var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
            $('.photoswipe__top__nav .wrede__button--close').on('click', function () {
                lightBox.close();
            });
            $('.photoswipe__nav .wrede__button--prev').on('click', function () {
                lightBox.prev();
            });
            $('.photoswipe__nav .wrede__button--next').on('click', function () {
                lightBox.next();
            });
            lightBox.init();
            lightBox.listen('close', function(){
                $('.photoswipe__nav .wrede__button--prev').off('click');
                $('.photoswipe__nav .wrede__button--next').off('click');
                $('.photoswipe__top__nav .wrede__button--close').off('click');
            });
        });
    });

})(jQuery);
